'use client';

import React, { useEffect } from 'react';
import styles from './EmailOtp.module.scss';
import EnterEmailStep from './steps/EnterEmailStep';
import EnterEmailCodeStep from './steps/EnterEmailCodeStep';
const EmailOtp = ({
  welcomeTitle,
  welcomeSubTitle,
  onEmailSubmit,
  onCodeSubmit,
  onResendOtp,
  onBackHome = () => {}
}) => {
  const [email, set$email] = React.useState('');
  const [step, set$step] = React.useState(1);
  const handleEmailStepSubmit = async submittedEmail => {
    const response = await onEmailSubmit(submittedEmail.toLowerCase());
    if (response?.error) return response;
    set$email(submittedEmail);
    set$step(2);
    return undefined;
  };
  useEffect(() => {
    const savedEmail = localStorage.getItem('user_email');
    set$email(savedEmail);
    localStorage.removeItem('user_email');
  }, []);
  const goToFirstStep = () => {
    set$step(1);
    onBackHome();
  };
  useEffect(() => {
    if (localStorage.getItem('sign-up-prefill-email')) {
      set$email(localStorage.getItem('sign-up-prefill-email'));
    }
  }, []);
  return <div className={styles.otp} data-sentry-component="EmailOtp" data-sentry-source-file="EmailOtp.jsx">
      {step === 1 && <EnterEmailStep initialValue={email} title={welcomeTitle} subTitle={welcomeSubTitle} onSubmit={handleEmailStepSubmit} />}
      {step === 2 && <EnterEmailCodeStep email={email} onSubmit={onCodeSubmit} onResendOtp={onResendOtp} onBack={goToFirstStep} />}
    </div>;
};
export default EmailOtp;