import { useQuery } from '@tanstack/react-query';

type SettingsResponse = {
  auth: {
    phoneCountryCode: string;
    country: string;
  };
};

const fetchPublicSettingsDirectly = async (): Promise<SettingsResponse> => {
  return new Promise(resolve => {
    resolve({
      auth: {
        phoneCountryCode: '+38',
        country: 'Ukraine',
      },
    });
  });
};

export const useSettings = () => {
  const { data, isLoading } = useQuery<SettingsResponse>({
    queryKey: ['settings'],
    queryFn: fetchPublicSettingsDirectly,
  });

  return { data, isLoading };
};
