'use client';

import React, { forwardRef } from 'react';
import { Typography, useTheme } from '@mui/material';
import { MuiOtpInput } from 'mui-one-time-password-input';
import styles from './OtpCodeField.module.scss';
interface OtpCodeFieldProps {
  code: string;
  onCodeChange: (...event: any[]) => void;
  error?: string | null;
  disabled?: boolean;
}
const OtpCodeField = forwardRef<HTMLInputElement, OtpCodeFieldProps>(({
  code,
  onCodeChange,
  error,
  disabled = false
}, ref) => {
  const theme = useTheme();
  const matchIsNumeric = (text: string) => {
    const isNumber = typeof text === 'number';
    const isString = typeof text === 'string';
    if (text === '') return true;
    return (isNumber || isString && text !== '') && !Number.isNaN(Number(text));
  };
  const validateChar = (value: string) => {
    return matchIsNumeric(value);
  };
  return <div className={styles.container}>
        <div className={styles.fieldsContainer}>
          <MuiOtpInput value={code} autoFocus onChange={onCodeChange} length={6} style={{
        gap: 8
      }} validateChar={validateChar} TextFieldsProps={{
        disabled,
        error: !!error,
        className: 'sq-otp-input',
        color: 'primary',
        inputProps: {
          inputMode: 'tel',
          autoComplete: 'new-password',
          ref
        },
        InputProps: {
          sx: {
            borderRadius: '8px',
            '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
              borderColor: theme.palette.primary.main
            }
          }
        },
        sx: {
          input: {
            height: 56,
            width: '100%',
            boxSizing: 'border-box',
            fontSize: 16,
            fontWeight: 500
          }
        }
      }} />
        </div>
        {error && <Typography className={styles.codeError} variant="body2">
            {error}
          </Typography>}
      </div>;
});
export default OtpCodeField;