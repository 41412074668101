'use client';

import React, { useEffect } from 'react';
import { Box, Button, Typography } from '@mui/material';
import { AxiosError } from 'axios';
import { useTranslations } from 'next-intl';
import PhoneInput from 'react-phone-input-2';
import { isMobilePhone } from 'validator';
import styles from '../MobileOtp.module.scss';
import { useSettings } from '@_/src/module/otp-auth/hooks/useSettingsOtp';
import 'react-phone-input-2/lib/material.css';
const EnterPhoneStep = ({
  title,
  subTitle,
  onSubmit
}) => {
  const settings = useSettings();
  const t = useTranslations('SigninCard');
  const [phone, set$phone] = React.useState('');
  const [isLoading, set$isLoading] = React.useState(false);
  const [showUserExistingError, set$showUserExistingError] = React.useState(false);
  const [showBadPhoneNumber, set$showBadPhoneNumber] = React.useState(false);
  const [error, set$error] = React.useState(null);
  const handlePhoneInputChange = value => {
    const numericString = value.replace(/[^0-9]/g, '');
    set$showBadPhoneNumber(false);
    set$phone(numericString);
  };
  const handleSubmit = async () => {
    if (!isMobilePhone(phone)) {
      set$showBadPhoneNumber(true);
      return;
    }
    try {
      set$isLoading(true);
      const response = await onSubmit(phone);
      if (response?.error === 'Conflict') {
        set$showUserExistingError(true);
      }
    } catch (ex) {
      if (ex instanceof AxiosError && ex.response.data?.message === 'account-disabled') {
        set$error(t('error.account-deactivated'));
      }
      // if (ex instanceof AxiosError && ex.response.status === 404) {
      //   set$error(t('error.account-not-found'));
      // }
    } finally {
      set$isLoading(false);
    }
  };
  useEffect(() => {
    if (localStorage.getItem('sign-up-prefill-phone') && !settings.isLoading) {
      set$phone(localStorage.getItem('sign-up-prefill-phone').replace(settings.data?.auth?.phoneCountryCode, ''));
    }
  }, [!settings.isLoading]);
  return <>
      <div className={styles.otp__header}>
        <Typography variant="h5" data-sentry-element="Typography" data-sentry-source-file="EnterPhoneStep.jsx">{title}</Typography>
        <Typography variant="body2" data-sentry-element="Typography" data-sentry-source-file="EnterPhoneStep.jsx">{subTitle}</Typography>
      </div>

      {!settings.isLoading && <div className={styles.number}>
          <div className={styles.number__field}>
            <Box sx={{
          '.react-tel-input .form-control:focus': {
            borderColor: theme => theme.palette.primary.main,
            boxShadow: theme => `0px 0px 0px 1px ${theme.palette.primary.main}`
          }
        }}>
              <PhoneInput country="us" isValid={() => !(showBadPhoneNumber || showUserExistingError || error)} specialLabel="Phone number" inputStyle={{
            width: '100%'
          }} defaultErrorMessage="Invalid phone number" value={phone} onChange={handlePhoneInputChange} />
            </Box>
            {error && <Typography color="error" sx={{
          ml: '14px',
          fontSize: '12px'
        }}>
                {error}
              </Typography>}
          </div>
        </div>}

      <Button fullWidth variant="contained" disabled={!phone || isLoading}
    // startIcon={isLoading ? ButtonLoader : null}
    onClick={handleSubmit} data-sentry-element="Button" data-sentry-source-file="EnterPhoneStep.jsx">
        {t('buttons.continue')}
      </Button>
    </>;
};
export default EnterPhoneStep;