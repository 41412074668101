'use client';

import { Box, Tab, Tabs } from '@mui/material';
import { useTranslations } from 'next-intl';
import React from 'react';
import { useSearchParams } from 'next/navigation';
import Logo from '@/components/Logo/Logo';
import ErrorBoundary from '@/components/ErrorBoundary/ErrorBoundary';
import EmailOtp from '../components/common/EmailOtp/EmailOtp';
import { OTP_MODE } from '../types';
import useOtp from '../components/hooks/useOtp';
import { setAccessCookie } from '../actions/setCookie';
import MobileOtp from '../components/common/MobileOtp/MobileOtp';
export default function AuthScreen() {
  const t = useTranslations('SigninCard');
  const searchParams = useSearchParams();
  const redirectTo = searchParams.get('redirectTo') || '/';
  const [valueMode, setValueMode] = React.useState(OTP_MODE.phone);
  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValueMode(newValue);
  };
  const {
    onOtpRequest,
    onResendOtp,
    onOtpCodeSubmit
  } = useOtp();
  const showPhoneMode = () => {
    setValueMode(OTP_MODE.phone);
  };
  const showEmailMode = () => {
    setValueMode(OTP_MODE.email);
  };
  const handleBackHome = () => {
    showPhoneMode();
  };

  // OTP
  const handleOtpRequest = async (emailOrPhoneNumber: any) => {
    const response = await onOtpRequest(emailOrPhoneNumber);
    return response;
  };
  const handleOtpCodeSubmit = async (emailOrPhoneNumber: any, code: any) => {
    const response = await onOtpCodeSubmit(emailOrPhoneNumber, code);
    if ('error' in response) {} else {
      await setAccessCookie(response.accessToken, response.refreshToken, redirectTo);
    }
    return response;
  };
  const handleOtpResend = async (callback: any) => {
    await onResendOtp();
    callback();
  };
  return <ErrorBoundary data-sentry-element="ErrorBoundary" data-sentry-component="AuthScreen" data-sentry-source-file="AuthScreen.tsx">
      <Box position="fixed" width="100%" height="100%" top={0} left={0} display="flex" alignItems="center" justifyContent="center" bgcolor="#F6FAFF" zIndex={10000000} overflow="auto" data-sentry-element="Box" data-sentry-source-file="AuthScreen.tsx">
        <Box position="fixed" top="44px" zIndex={1} justifyContent="center" data-sentry-element="Box" data-sentry-source-file="AuthScreen.tsx">
          <Logo data-sentry-element="Logo" data-sentry-source-file="AuthScreen.tsx" />
        </Box>

        <Box position="relative" zIndex={2} bgcolor="white" flexGrow="0" border="1px solid #E0E0E0" padding="40px" borderRadius={8} minWidth={390} maxWidth={450} data-sentry-element="Box" data-sentry-source-file="AuthScreen.tsx">
          {valueMode === OTP_MODE.email ? <EmailOtp welcomeTitle={t('otp.signInTitle')} welcomeSubTitle={t('otp.signInHelperTextEmail')} onEmailSubmit={handleOtpRequest} onCodeSubmit={handleOtpCodeSubmit} onResendOtp={handleOtpResend} onBackHome={handleBackHome} /> : null}

          {valueMode === OTP_MODE.phone ? <MobileOtp welcomeTitle={t('otp.signInTitle')} welcomeSubTitle={t('otp.signInHelperTextPhone')} onPhoneNumberSubmit={handleOtpRequest} onCodeSubmit={handleOtpCodeSubmit} onResendOtp={handleOtpResend} onBackHome={handleBackHome} /> : null}

          <Box pb="20px" data-sentry-element="Box" data-sentry-source-file="AuthScreen.tsx" />
          <Box display="flex" justifyContent="center" data-sentry-element="Box" data-sentry-source-file="AuthScreen.tsx">
            <Tabs value={valueMode} onChange={handleChange} data-sentry-element="Tabs" data-sentry-source-file="AuthScreen.tsx">
              <Tab label="Phone" value={OTP_MODE.phone} data-sentry-element="Tab" data-sentry-source-file="AuthScreen.tsx" />
              <Tab label="Email" value={OTP_MODE.email} data-sentry-element="Tab" data-sentry-source-file="AuthScreen.tsx" />
            </Tabs>
          </Box>
        </Box>
      </Box>
    </ErrorBoundary>;
}