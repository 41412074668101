'use client';

import React from 'react';

import {
  isSignUpComplete,
  confirmOTP,
  requestOTP,
  requestOTPForEmailOrPhoneUpdate,
  confirmOTPSkipAuthority,
} from '../../services/otp-api-service';

const useOtp = () => {
  const [emailOrPhoneNumber, set$emailOrPhoneNumber] = React.useState(null);
  const [otpRequestId, set$otpRequestId] = React.useState(null);
  const [tokens, set$tokens] = React.useState(null);

  const sendOtp = async emailOrPhoneNumber => {
    const { nonce: requestId } = await requestOTP({
      emailOrPhoneNumber,
    });
    return requestId;
  };

  const sendOtpForEmailOrPhoneUpdate = async emailOrPhoneNumber => {
    const { nonce: requestId } = await requestOTPForEmailOrPhoneUpdate({
      emailOrPhoneNumber,
    });
    return requestId;
  };

  const handleUserSignUpCompleteCheck = async emailOrPhoneNumber => {
    return isSignUpComplete(emailOrPhoneNumber);
  };

  const resendOtp = async () => {
    const requestId = await sendOtp(emailOrPhoneNumber);
    set$otpRequestId(requestId);
  };

  const handleOtpRequest = async (emailOrPhoneNumber = '') => {
    set$emailOrPhoneNumber(emailOrPhoneNumber);
    const requestId = await sendOtp(emailOrPhoneNumber);
    set$otpRequestId(requestId);
  };

  const handleOtpRequestForEmailOrPhoneUpdate = async (
    emailOrPhoneNumber = ''
  ) => {
    set$emailOrPhoneNumber(emailOrPhoneNumber);
    const requestId = await sendOtpForEmailOrPhoneUpdate(emailOrPhoneNumber);
    set$otpRequestId(requestId);
  };

  const handleOtpCodeSubmit = async (
    emailOrPhoneNumber,
    code = '',
    skipAuthority = false
  ) => {
    try {
      const requestFuntion = skipAuthority
        ? confirmOTPSkipAuthority
        : confirmOTP;

      const response = await requestFuntion({
        emailOrPhoneNumber,
        code,
        nonce: otpRequestId,
      });

      const { accessToken, refreshToken } = response || {};
      const newTokens = {
        accessToken: accessToken?.token,
        refreshToken: refreshToken?.token,
      };
      set$tokens(newTokens);

      return newTokens;
    } catch (e) {
      return { error: e };
    }
  };

  return {
    tokens,
    onSendOtp: sendOtp,
    onResendOtp: resendOtp,
    onOtpRequest: handleOtpRequest,
    onOtpRequestForEmailOrPhoneUpdate: handleOtpRequestForEmailOrPhoneUpdate,
    onOtpCodeSubmit: handleOtpCodeSubmit,
    onUserSignUpComplete: handleUserSignUpCompleteCheck,
  };
};

export default useOtp;
