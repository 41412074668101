'use client';

import React, { useEffect } from 'react';
import classNames from 'classnames';
import { Button, Typography } from '@mui/material';
import { useTranslations } from 'next-intl';
import styles from '../EmailOtp.module.scss';
import OtpCodeField from '../../OtpCodeField/OtpCodeField';
import ArrowLeftIcon from '../../../icons/ArrowLeftIcon';
const CODE_LENGTH = 6;
const EnterEmailCodeStep = ({
  email,
  onSubmit,
  onResendOtp,
  onBack
}) => {
  const t = useTranslations('SigninCard');
  const [code, set$code] = React.useState('');
  const [prevCode, set$prevCode] = React.useState('');
  const [error, set$error] = React.useState('');
  const [isProcessing, set$isProcessing] = React.useState(false);
  const [isResendDisabled, set$isResendDisabled] = React.useState(false);
  const handleSubmit = async () => {
    set$isProcessing(true);
    const response = await onSubmit(email, code);
    if (response?.error?.response?.data?.error?.includes('Bad Request')) {
      set$error(t('otp.wrongCode'));
    } else if (response?.error?.response?.data?.messag) {
      set$error(t(response?.error.response.data.message));
    }
    set$isProcessing(false);
  };
  const resetCallback = () => {
    set$code('');
    set$error('');
  };
  const handleOtpResend = () => {
    if (isResendDisabled) return;
    set$isResendDisabled(true);
    onResendOtp(resetCallback);
  };
  const handleCodeChange = newCode => {
    set$prevCode(code);
    set$code(newCode);
  };
  useEffect(() => {
    set$error('');
  }, [code]);
  useEffect(() => {
    set$error('');
    const isFilledLastCodeNumber = code?.length === CODE_LENGTH && prevCode?.length === CODE_LENGTH - 1;
    if (isFilledLastCodeNumber) {
      handleSubmit();
    }
  }, [code, prevCode]);
  useEffect(() => {
    if (!isResendDisabled) return;
    setTimeout(() => {
      set$isResendDisabled(false);
    }, 1000 * 10);
  }, [isResendDisabled]);
  return <>
      <div className={classNames(styles.otp__back, styles.otp__link)} onClick={onBack}>
        <ArrowLeftIcon data-sentry-element="ArrowLeftIcon" data-sentry-source-file="EnterEmailCodeStep.jsx" />
        Back
      </div>
      <div className={styles.otp__header}>
        <Typography variant="h5" data-sentry-element="Typography" data-sentry-source-file="EnterEmailCodeStep.jsx">{t('otp.confirmYourEmail')}</Typography>
        <Typography variant="body2" data-sentry-element="Typography" data-sentry-source-file="EnterEmailCodeStep.jsx">
          {t('otp.enterEmailCode')} {email}
        </Typography>
      </div>

      <OtpCodeField code={code} codeLength={CODE_LENGTH} onCodeChange={handleCodeChange} error={error} data-sentry-element="OtpCodeField" data-sentry-source-file="EnterEmailCodeStep.jsx" />

      <div className="form__row">
        <Typography variant="body1" component="span" className={classNames(styles.otp__link, {
        [styles.otp__link_disabled]: isResendDisabled
      })} style={{
        fontWeight: 500
      }} onClick={handleOtpResend} data-sentry-element="Typography" data-sentry-source-file="EnterEmailCodeStep.jsx">
          {t('otp.sendNewCode')}
        </Typography>
      </div>

      <Button fullWidth variant="contained" disabled={!code || isProcessing} onClick={handleSubmit} data-sentry-element="Button" data-sentry-source-file="EnterEmailCodeStep.jsx">
        Continue
      </Button>
    </>;
};
export default EnterEmailCodeStep;