'use client';

import React, { useEffect } from 'react';
import { TextField, Button, Typography } from '@mui/material';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup/dist/yup.js';
import * as yup from 'yup';
import { AxiosError } from 'axios';
import { useTranslations } from 'next-intl';
import styles from '../EmailOtp.module.scss';
// import { ButtonLoader } from '../../../../../../JsonForm/components/SQWizardFooter';

const EnterEmailStep = ({
  title,
  subTitle,
  onSubmit,
  initialValue
}) => {
  const t = useTranslations('SigninCard');
  const [showUserExistingError, set$showUserExistingError] = React.useState(false);
  const [isLoading, set$isLoading] = React.useState(false);
  const formStore = useForm({
    resolver: yupResolver(yup.object().shape({
      email: yup.string().email(t('error.invalid_email_format')).required(t('error.field_required'))
    })),
    defaultValues: {
      email: initialValue || ''
    }
  });
  const {
    control,
    handleSubmit,
    reset: resetForm,
    setError,
    watch,
    formState,
    setValue
  } = formStore;
  const emailFieldValue = watch('email');
  const handleSubmitValidForm = async ({
    email
  }) => {
    try {
      set$isLoading(true);
      const response = await onSubmit(email);
      if (response?.error === 'Conflict') {
        set$showUserExistingError(true);
        setError('email', {
          message: t('A user with this email already exists in the system'),
          type: 'existing_user'
        });
      } else {
        resetForm();
      }
    } catch (error) {
      if (error instanceof AxiosError && error.response.data?.message === 'account-disabled') {
        setError('email', {
          message: t('Your account has been deactivated. Please contact the administrator for assistance.'),
          type: 'account_disabled'
        });
      }

      // if (error instanceof AxiosError && error.response.status === 404) {
      //   setError('email', {
      //     message: t('error.account-not-found'),
      //     type: 'account_disabled',
      //   });
      // }
    } finally {
      set$isLoading(false);
    }
  };
  useEffect(() => {
    if (showUserExistingError) {
      set$showUserExistingError(false);
      setError('email', null);
    }
  }, [emailFieldValue]);
  useEffect(() => {
    if (initialValue) {
      setValue('email', initialValue);
      localStorage.removeItem('sign-up-prefill-email');
    }
  }, [initialValue]);
  return <>
      <div className={styles.otp__header}>
        <Typography variant="h5" data-sentry-element="Typography" data-sentry-source-file="EnterEmailStep.jsx">{title}</Typography>
        <Typography variant="body2" data-sentry-element="Typography" data-sentry-source-file="EnterEmailStep.jsx">{subTitle}</Typography>
      </div>

      <Controller control={control} name="email" render={({
      field,
      formState
    }) => <TextField {...field} fullWidth label="Email" variant="outlined" onKeyUp={e => {
      if (e.key === 'Enter') {
        e.preventDefault();
        handleSubmit(handleSubmitValidForm);
      }
    }} onChange={e => field.onChange({
      ...e,
      target: {
        ...e.target,
        value: e.target.value.trim().replaceAll(' ', '').toLowerCase()
      }
    })} error={!!formState.errors?.email} helperText={formState.errors?.email?.message} />} data-sentry-element="Controller" data-sentry-source-file="EnterEmailStep.jsx" />

      <Button fullWidth disabled={!emailFieldValue || isLoading || formState.isSubmitted && !formState.isValid}
    // startIcon={isLoading ? ButtonLoader : null}
    onClick={handleSubmit(handleSubmitValidForm)} data-sentry-element="Button" data-sentry-source-file="EnterEmailStep.jsx">
        Continue
      </Button>
    </>;
};
export default EnterEmailStep;