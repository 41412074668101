'use client';

import React from 'react';
import styles from './MobileOtp.module.scss';
import EnterPhoneStep from './steps/EnterPhoneStep';
import EnterPhoneCodeStep from './steps/EnterPhoneCodeStep';
const MobileOtp = ({
  welcomeTitle,
  welcomeSubTitle,
  onPhoneNumberSubmit,
  onCodeSubmit,
  onResendOtp,
  onBackHome = () => {}
}) => {
  const [phone, set$phone] = React.useState('');
  const [step, set$step] = React.useState(1);
  const handlePhoneStepSubmit = async phoneNumber => {
    const completePhoneNumber = `+${phoneNumber}`;
    const response = await onPhoneNumberSubmit(completePhoneNumber);
    if (response?.error) return response;
    set$step(2);
    set$phone(completePhoneNumber);
    return undefined;
  };
  const goToFirstStep = () => {
    set$step(1);
    onBackHome();
  };
  return <div className={styles.otp} data-sentry-component="MobileOtp" data-sentry-source-file="MobileOtp.jsx">
      {step === 1 && <EnterPhoneStep title={welcomeTitle} subTitle={welcomeSubTitle} onSubmit={handlePhoneStepSubmit} />}
      {step === 2 && <EnterPhoneCodeStep phone={phone} onSubmit={onCodeSubmit} onResendOtp={onResendOtp} onBack={goToFirstStep} />}
    </div>;
};
export default MobileOtp;