'use client';

import { useEffect } from 'react';
import { useRouter, useSearchParams } from 'next/navigation';
import Cookies from 'js-cookie';
import AuthScreen from '@_/src/module/otp-auth/pages/AuthScreen';
export default function Signin() {
  const router = useRouter();
  const searchParams = useSearchParams();
  const redirectTo = searchParams.get('redirectTo') || '/';
  useEffect(() => {
    if (Cookies.get('access_token')) {
      router.push(redirectTo);
    }
  }, []);
  return <AuthScreen data-sentry-element="AuthScreen" data-sentry-component="Signin" data-sentry-source-file="page.tsx" />;
}