import api from '@/lib/api';

const API_PREFIX = '/api/v1';

export const requestOTP = async data => {
  const res = await api.post(`${API_PREFIX}/auth/otp/request`, {
    emailOrPhoneNumber: data.emailOrPhoneNumber,
  });
  return res?.data?.data;
};

export const requestOTPForEmailOrPhoneUpdate = async data => {
  const res = await api.post(`${API_PREFIX}/auth/update/otp/request`, {
    emailOrPhoneNumber: data.emailOrPhoneNumber,
  });
  return res?.data?.data;
};

export const confirmOTP = async data => {
  const res = await api.post(`${API_PREFIX}/auth/otp/confirm`, {
    emailOrPhoneNumber: data.emailOrPhoneNumber,
    code: data.code,
    nonce: data.nonce,
  });
  return res?.data?.data;
};

export const confirmOTPSkipAuthority = async data => {
  const res = await api.post(
    `${API_PREFIX}/auth/otp/confirm?skipAuthority=true`,
    {
      emailOrPhoneNumber: data.emailOrPhoneNumber,
      code: data.code,
      nonce: data.nonce,
    }
  );
  return res?.data?.data;
};

export const savePersonalInfo = async (userId, data, config) => {
  const res = await api.post(`/api/user/register`, data, config);
  return res?.data?.data;
};

export const isSignUpComplete = async emailOrPhoneNumber => {
  const res = await api.post(`/api/user/is-signup-complete/`, {
    emailOrPhoneNumber,
  });

  return res?.data?.data;
};
