import type { ReactNode } from 'react';
import React from 'react';
interface ErrorBoundaryProps {
  children: ReactNode;
}
interface ErrorBoundaryState {
  hasError: boolean;
  error: Error | null;
  errorInfo: React.ErrorInfo | null;
}
class ErrorBoundary extends React.Component<ErrorBoundaryProps, ErrorBoundaryState> {
  constructor(props: ErrorBoundaryProps) {
    super(props);
    this.state = {
      hasError: false,
      error: null,
      errorInfo: null
    };
  }
  static getDerivedStateFromError(error: Error): Partial<ErrorBoundaryState> {
    return {
      hasError: true,
      error
    };
  }
  componentDidCatch(error: Error, errorInfo: React.ErrorInfo): void {
    this.setState({
      error,
      errorInfo
    });
    // Логування помилки на сервер або інше оброблення
    console.error('ErrorBoundary caught an error', error, errorInfo);
  }
  render() {
    const {
      hasError,
      error,
      errorInfo
    } = this.state;
    const {
      children
    } = this.props;
    if (hasError) {
      return <div data-sentry-component="ErrorBoundary" data-sentry-source-file="ErrorBoundary.tsx">
          <h1>Щось пішло не так.</h1>
          <details style={{
          whiteSpace: 'pre-wrap'
        }}>
            {error && error.toString()}
            <br />
            {errorInfo && errorInfo.componentStack}
          </details>
        </div>;
    }
    return children;
  }
}
export default ErrorBoundary;