'use client';

import React, { useEffect } from 'react';
import { Button, Typography } from '@mui/material';
import classNames from 'classnames';
import { useTranslations } from 'next-intl';
import OtpCodeField from '../../OtpCodeField/OtpCodeField';
import ArrowLeftIcon from '../../../icons/ArrowLeftIcon';
import styles from '../MobileOtp.module.scss';
const CODE_LENGTH = 6;
const EnterPhoneCodeStep = ({
  phone,
  onSubmit,
  onResendOtp,
  onBack
}) => {
  const t = useTranslations('SigninCard');
  const [isProcessing, set$isProcessing] = React.useState(false);
  const [code, set$code] = React.useState('');
  const [prevCode, set$prevCode] = React.useState('');
  const [error, set$error] = React.useState(false);
  const [isResendDisabled, set$isResendDisabled] = React.useState(false);
  const handleSubmit = async () => {
    set$isProcessing(true);
    try {
      const response = await onSubmit(phone, code);
      if (response?.error?.response.statusText.includes('Bad Request')) {
        set$error(t('otp.wrongCode'));
      } else if (response?.error?.response?.data?.message) {
        set$error(t(response?.error.response.data.message));
      }
    } catch (ex) {
      console.error(ex);
    }
    set$isProcessing(false);
  };
  const resetCallback = () => {
    set$code('');
    set$error(false);
  };
  const handleOtpResend = () => {
    if (isResendDisabled) return;
    set$isResendDisabled(true);
    onResendOtp(resetCallback);
  };
  useEffect(() => {
    set$error('');
  }, [code]);
  useEffect(() => {
    if (!isResendDisabled) return;
    setTimeout(() => {
      set$isResendDisabled(false);
    }, 1000 * 10);
  }, [isResendDisabled]);
  useEffect(() => {
    set$error('');
  }, [code, prevCode]);
  const handleCodeChange = newCode => {
    set$prevCode(code);
    set$code(newCode);
  };
  return <>
      <div className={classNames(styles.otp__back, styles.otp__link)} onClick={onBack}>
        <ArrowLeftIcon data-sentry-element="ArrowLeftIcon" data-sentry-source-file="EnterPhoneCodeStep.jsx" />
        Back
      </div>
      <div className={styles.otp__header}>
        <Typography variant="h5" data-sentry-element="Typography" data-sentry-source-file="EnterPhoneCodeStep.jsx">{t('otp.confirmPhone')}</Typography>
        <Typography variant="body2" data-sentry-element="Typography" data-sentry-source-file="EnterPhoneCodeStep.jsx">
          {t('otp.confirmPhoneHelperText')} {phone}
        </Typography>
      </div>

      <OtpCodeField code={code} codeLength={CODE_LENGTH} onCodeChange={handleCodeChange} error={error} data-sentry-element="OtpCodeField" data-sentry-source-file="EnterPhoneCodeStep.jsx" />

      <div className="form__row">
        <Typography variant="body1" component="span" className={classNames(styles.otp__link, {
        [styles.otp__link_disabled]: isResendDisabled
      })} style={{
        fontWeight: 500
      }} onClick={handleOtpResend} data-sentry-element="Typography" data-sentry-source-file="EnterPhoneCodeStep.jsx">
          {t('otp.sendNewCode')}
        </Typography>
      </div>

      <Button fullWidth disabled={!code || isProcessing} onClick={handleSubmit} data-sentry-element="Button" data-sentry-source-file="EnterPhoneCodeStep.jsx">
        Continue
      </Button>
    </>;
};
export default EnterPhoneCodeStep;